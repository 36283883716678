body {
    background: rgb(227, 233, 245);
    background: linear-gradient(256deg, rgba(227, 233, 245, 1) 0%, rgba(234, 230, 246, 1) 18%, rgba(239, 247, 252, 1) 34%, rgba(241, 232, 232, 1) 65%, rgba(232, 226, 248, 1) 100%);
}

.dashboard-container {
    max-width: 1024px;
}

.dashboard-title-container {
    margin-top: 40px;
    margin-bottom: 0px;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 100px;
    padding: 10px 0px 10px 0px;
    box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 768px) {
    .dashboard-title-container {
        padding-top: 50px;
        padding-bottom: 50px;
    }
}

.dashboard-title-container h1 {
    margin-left: 0;
    margin-right: 40px;
    font-weight: bold;
    font-size: 36px;
    white-space: pre-line;
}

.hero-logo {
    width: 188px;
    height: auto;
}

.dashboard-stepper-container {
    margin-top: 20px;
    margin-bottom: 40px;
}

.dashboard-title-text-container {
    margin-right: 40px;
    white-space: pre-line;
}

.dashboard-subtitle {
    margin-top: 10px;
    font-weight: 500;
    
    color: #8B8DCD;
}

.dashboard-card-list {
    max-width: 100%;
}