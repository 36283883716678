.tenant-stat-container {
    background-color: white;
    padding-top: 10px;
    padding-bottom: 10px;
    min-height: 60px;
}

.org-stats-header {
    font-size: 24px;
    font-weight: 700;
    color: #314184;
}

.org-stats-category-icon {
    height: 30px;
    width: auto;
}

.org-stats-category-percentage {
    font-size: 20px;
    font-weight: bold;
    color: #5364AA;
}

.side-note {
    font-size: smaller;
    float: right;
}