.obtain-license-container {
    margin-top: 40px;
    justify-content: center;
    align-items: center;
    text-align: center;
    display: flex;
}

.obtain-license-content-panel {
    position: relative;
    background: rgb(255, 255, 255, 0.8);
    background: linear-gradient(118deg, rgba(255, 255, 255, 0.8) 0%, rgba(233, 246, 255, 0.8) 100%);
    max-width: 100%;
    min-height: 70vh;
}

.obtain-license-content {
    margin-top: 20px;
}

.obtain-license-content > * {
    margin-left: auto;
    margin-right: auto;
    max-width: 800px; 
}

.obtain-license-content h1 {
    font-size: 1.8rem;
    margin-bottom: 1.4rem;
}

.obtain-license-content h2 {
    font-size: 1.4rem;
    margin-top: 1.8rem;
    margin-bottom: .8rem;
}

.obtain-license-content h3 {
    font-size: 1.2rem;
    margin-top: 1.8rem;
    margin-bottom: .8rem;
}

.obtain-license-content h4 {
    font-size: 1rem;
    margin-top: 1.8rem;
    margin-bottom: .8rem;
}

.obtain-license-content ul {
    margin-top: -.5rem;
}

.obtain-license-content ol {
    margin-top: -.5rem;
}

.obtain-license-content button {
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);
    margin: 20px 0;
}

.obtain-license-container .hero-logo {
    width: 100%;
    height: auto;
}

.obtain-license-container .hero-logo .coach-image {
    max-width: 100%;
}

.obtain-license-container .call-to-action {
    margin-top: 50px;
}

.obtain-license-footer {
    font-size: smaller;
}