.stepper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin: 20px 0;

  user-select: none;
}

.step {
  height: 42px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  position: relative;
}

.step .step-unselected {
  width: 36px;
  height: 36px;

  color: white;
  font-size: 18px;
  font-weight: bold;

  border-radius: 50%;
  border-width: 0;
  background-color: #d9d9d9;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  position: relative;
  top: 6px;
}

.step .step-selected {
  white-space: nowrap;  /* Prevents text from wrapping */
  display: inline-block; /* Ensures the button size is only as wide as necessary */
  height: 42px;
  padding-left: 12px;
  padding-right: 12px;

  color: white;
  font-size: 18px;
  font-weight: bold;

  border-radius: 10px;
  border-width: 0;
  background-color: #D9D9D9;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  position: relative;
  top: 3px;
}

.step .step-available {
  background-color: #AD9DDE;
}

.step .step-navigate {
  width: 36px;
  height: 36px;

  color: white;
  font-size: 18px;
  font-weight: bold;

  border-radius: 50%;
  border-width: 0;
  background-color: #AD9DDE;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  position: relative;
  top: 6px;
}

.step .step-navigate:disabled {
  opacity: 0;
}

.step .step-line {
  top: 50%;
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: #D9D9D9;
  z-index: 1;
  transform: translateX(-50%);
}

.step .step-line-available {
  background-color: #AD9DDE;
}